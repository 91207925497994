<!-- splash screen -->
<div #splashScreen id="splash-screen">
  <img src="./assets/media/logos/logo.svg" alt="Logo MiTT" />
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>
