// Spain
export const locale = {
    lang: 'es',
    data: {
        TRANSLATOR: {
            SELECT: 'Elige tu idioma',
        },
        TOPBAR: {
            HI: 'Hola',
            TITLE: 'Administración'
        },
        MENU: {
            DASHBOARD: 'Tablero',
            PATIENTS: 'Pacientes',
            PATIENT_CONTROL: 'Seguimiento de pacientes',
            BRACELET: 'Pulseras',
            ADMISSIONDOCUMENTS: 'Documentos subidos',
            ROOM_CONTROL: 'Control de salas',
            PERFORMEDTESTS: 'Cuestionarios Realizados',
            SUBSCRIPTIONS: 'Suscripciones',
            TEST: 'Cuestionarios',
            CATEGORY: 'Categoría',
            CONTENT: 'Contenido',
            MULTIMEDIA: 'Multimedia',
            PROGRAM: 'Programa',
            PROGRAMS_CONTENTS: 'Programas y Contenidos',
            ADMINISTRATION: 'Administración',
            USER: 'Usuario',
            NEWS: 'Noticias',
            GROUP: 'Roles',
            CENTERS: 'Centros',
            QUESTIONS: 'Preguntas',
            SALUS_PATIENT_USER: 'Pacientes del HIS',
            UNASSIGNED_USER: 'Pacientes sin sincronizar',
            ADMISSION: 'Admisiones',
            DOCUMENT: 'Documentos',
            NOTIFICATIONS: 'Notificaciones',
            DOCUMENT_VALIDATION: 'Validación de documentos',
            APPOINTMENTS: 'Citas',
        },
        USER: {
            BTN: {
                CLEAR: 'Desasignar',
                EDIT: 'Editar',
                DELETE: 'Eliminar'
            }
        },
        PAGINATOR: {
            LOADING: 'Cargando',
            SHOWING_ROWS: 'Mostrando filas',
            TO: 'a',
            OF: 'de'
        },
        FORM: {
            BUTTON: {
                ASSIGN: 'Asignar',
                CANCEL: 'Cancelar',
                CREATE_NEW: 'Crear nuevo',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                VIEW: 'Ver ficha',
                CREATE: 'Crear',
                BACK: 'Volver',
                RESET: 'Restaurar',
                SAVE: 'Guardar',
                ADD: 'Adicionar',
                SEND: 'Enviar',
                CHANGE: 'Cambiar',
                UPLOAD: 'Cargar',
                CHANGE_PASSWORD: 'Cambiar contraseña',
                SELECT: {
                    ALL: 'TODOS',
                    PENDING: 'Pendiente',
                    REJECT: 'Rechazado',
                    VALIDATING: 'Pendiente de validar',
                    APPROVED: 'Aprobado',
                    GENDER: 'Genero',
                    GENDER_MAN: 'Hombre',
                    GENDER_WOMAN: 'Mujer',
                    STATUS: 'Estado',
                    LOCALE_FEEDBACK: 'Seleccione un idioma',
                    LOCALE_EN: 'English',
                    LOCALE_ES: 'Español',
                    LOCALE_CA: 'Català',
                    ANSWER_TYPE: 'Tipo de respuesta',
                    GROUP_FEEDBACK: 'Seleccione un grupo',

                },
                ROLES: {
                    ROLES: 'Roles',
                    ESPECIALIST: 'Especialista',
                    ADMIN: 'Administrador',
                    USER: 'Usuario',
                    PATIENT: 'Paciente'
                }
            },
            VALIDATION: {
                INVALID: 'Campo inválido',
                REQUIRED: 'Campo requerido',
                MIN_LENGTH: 'La longitud mínima de {{name}} es {{min}}',
                AGREEMENT_REQUIRED: 'Aceptar los Términos y Condiciones es obligatorio',
                NOT_FOUND: 'No se ha encontrado {{name}}',
                INVALID_LOGIN: 'El login es incorrecto',
                REQUIRED_FIELD: 'Este campo es requerido',
                MIN_LENGTH_FIELD: 'Mínima longitud del campo:',
                MAX_LENGTH_FIELD: 'Máxima longitud del campo:',
                INVALID_FIELD: 'El campo no es válido',
                VALIDATE_PASSWORD_REQUIRED: 'Validación de la contraseña requerida',
                VALIDATE_PASSWORD_MIN_SYMBOLS: 'Validación mínima de caracteres en la contraseña',
                VALIDATE_PASSWORD_MAX_SYMBOLS: 'Validación máxima de caracteres en la contraseña',
                VALIDATE_EMAIL_REQUIRE: 'Validación de correo requerida',
                VALIDATE_EMAIL_INVALID: 'Validación de correo invalida',
                VALIDATE_EMAIL_MIN_SYMBOLS: 'Validación mínima de caracteres en el correo',
                VALIDATE_EMAIL_MAX_SYMBOLS: 'Validación máxima de caracteres en el correo',
                NAME_REQUIRED: 'Este campo es requerido',
                MAX_LENGTH: 'Cantidad de caracteres excedida',
                DESCRIPTION_REQUIRED: 'Este campo es requerido',
                SUCCESS: 'El valor de este campo es correcto',
            },
            PLACEHOLDER: {
                SEARCH: 'Buscar'
            },
            HELPER: {
                SEARCH_IN_ALL_FIELDS: 'Buscar en todos los campos',
                SEARCH_BY_NAME_LASTNAME: 'Buscar por nombre o apellidos',
                SEARCH_BY_NAME: 'Buscar por nombre',
                SEARCH_IN_STATUS: 'Filtrar por estados',
                SEARCH_IN_CATEGORY: 'Filtrar por categorías'
            }
        },
        EMPTY: {
            NO_DATA: 'No hay datos para mostrar',
            SALUS_PATIENT_EMPTY: 'No se han encontrado datos en el sistema hospitalario'
        },
        LABEL: {
            NAME: 'Nombre',
            DATE: 'Fecha',
            LOCALE: 'Idioma',
            ROLE: 'Rol',
            PASSWORD: 'Contraseña',
            SINGLE: 'Única',
            MULTIPLE: 'Multiple',
            OPTIONS: 'Opciones',
            OPTION: 'Opción',
            OPTION_ORDER: 'Orden de Opción',
            ANSWER_TYPE: 'Tipo de Respuesta',
            TOTAL_OPTIONS: 'Total de Opciones',
            TEXT: 'Texto',
            TITLE: 'Título',
            PUBLISHED: 'Publicado',
            PERIODICITY: 'Periodicidad',
            QUESTIONS: 'Preguntas',
            QUESTION: 'Preguntas',
            DESCRIPTION: 'Descripción',
            TOTAL_QUESTIONS: 'Total de Preguntas',
            ACTIVE: 'Activo',
            INACTIVE: 'Inactivo',
            PREMIUM: 'Premium',
            FREE: 'Gratis',
            STATUS: 'Estado',
            USERNAME: 'Nombre de Usuario',
            PERFORMEDTESTS: 'Cuestionarios Realizados',
            SUBSCRIPTION_TIME: 'Subscrito hasta',
            GROUP: 'Grupo',
            CODE: 'Código',
            NOT_HAVE_UNASSIGNED_USER: 'No hay pacientes pendientes por sincronizar',
            BRACELET: 'Pulsera',
            PLAN: {
                SUBSCRIPTIONS: 'Suscripciones por planes'
            },
            ACTIONS: 'Acciones',
            ALL: 'Todos',
            INFORMATION: 'Información',
            EMAIL: 'Correo electrónico',
            CREATED_AT: 'Fecha de creación',
            UPDATED_AT: 'Fecha de actualización',
            LAST_ACTIVITY: 'Última actividad',
            USER_INFO: 'Información de usuario',
            WATCH: 'Ver',
            LASTNAME: '2do Apellido',
            USER_ID: 'ID de usuario',
            INTERNAL_NAME: 'Identificador interno',
            CENTER: 'Centro',
            ROLES: 'Rol',
            NEW_PASSWORD: 'Nueva contraseña',
            PASSWORD_CONFIRM: 'Confirme contraseña',
            SELF_GENERATED_PASSWORD: 'Contraseña autogenerada',
            CREATE_NEW: 'Nuevo',
            CATEGORY_ORDER: 'Orden',
            SELECTED_MULTIMEDIA: 'Multimedia seleccionada',
            AUTHOR: 'Autor',
            TOTAL_MULTIMEDIAS: '# de multimedias',
            TOTAL_CONTENTS: '# de contenidos',
            TAGS: 'Etiquetas',
            UPLOAD_FILES: 'Subir multimedia',
            SUBSCRIPTION: 'Subscripcion',
            YES: 'Si',
            NO: 'No',
            QUESTION_ORDER: 'Orden de pregunta',
            SYNC: 'Sincronizar',
            NHC: 'Historia',
            BIRTHDAY: 'Fecha Nacimiento',
            GENDER: 'Genero',
            MOBILE: 'Telefono',
            IMAGE: 'Imagen',
            OLD_IMAGE: 'Imagen Actual',
            CITY: 'Ciudad',
            POSTAL_CODE: 'Codigo postal',
            ADDRESS: 'Direccion',
            INSURANCE: 'Aseguradora',
            SURNAME: '1er Apellido',
            PREVIEW:    'Vista previa',
            PATIENT:    'Paciente',
            EDIT:    'Editar',
            BODY:    'Cuerpo',
            SEND_NOTIFICATION:  'Enviar Notificación',
            ADDRESS_TO: 'Dirigido a',
            TOPIC: 'Tema',
            ACTUAL_STATE: 'Estado actual',
            AVAILABLE_APP: '¡Tenemos una App disponible!',
            AVAILABLE_APP_TEXT: 'Desde la App podrás tener un seguimiento integral del familiar',
            AVAILABLE_APP_STAY: 'Continuar navegando',
            AVAILABLE_APP_CHANGE_APP: 'Obtener la App',
            TOPICS: {
                GENERAL: 'General',
                NEW_CONTENT: 'Nuevo contenido',
            },
            MARKDOWN_TOOLBAR:   {
                BOLD:   'Negrita',
                ITALIC:   'Itálica',
                HEADER:   'Encabezado',
                QUOTE:   'Cita',
                CODE:   'Código',
                LINK:   'Enlace',
                UNORDERED_LIST:   'Lista sin orden',
                ORDERED_LIST:   'Lista ordenada',
                TASK_LIST:   'Lista de tareas',
                MENTION:   'Mención',
                REFERENCE:   'Referencia',
            }
        },
        MESSAGE: {
            LOADING: {
                LOADING_REQUIRED_DATA: 'Cargando datos...',
                UPDATING_GROUP: 'Actualizando Grupo...',
                CREATING_GROUP: 'Creando Grupo...',
                SENDING_NOTIFICATION: 'Enviando Notificación...',
                UPDATING_USER: 'Actualizando Usuario...',
                CREATING_USER: 'Creando Usuario...',
                UPDATING_NEWS: 'Actualizando Noticia...',
                CREATING_NEWS: 'Creando Noticia...',
                DELETING_USER: 'Eliminando Usuario...',
                BRACELET_CLEAR: 'Desasignando pulsera...',
                UPLOADING_MULTIMEDIA: 'Subiendo multimedia al servidor...'
            },
            SAVING: {
                SAVING_DATA: 'guardando datos...'
            },
            SUCCESS: {
                TEST_USER: 'Cuestionario guardado con éxito',
                SAVE_GROUP: 'Grupo guardado con éxito',
                SAVE_USER: 'Usuario guardado con éxito',
                SAVE_NEWS: 'Noticia guardada con éxito',
                SEND_NOTIFICATION: 'Notificación enviada con éxito',
                INCORRECT_OPTIONS_AMOUNT: 'Cantidad de opciones incorrecta',
                UPDATE_CATEORY: 'Categoría guardada con éxito',
                SAVE_PROGRAM: 'Programa guardado con éxito',
                SAVE_CONTENT: 'Contenido guardado con éxito',
                DELETE_NEWS: 'Noticia eliminada con éxito'
            },
            ERROR: {
                TEST_USER: 'Ha ocurrido un error guardando el cuestionario',
                NOTIFICATION: 'Ha ocurrido un enviando la notificación',
                SAVE_GROUP: 'Ha ocurrido un error guardando el grupo',
                SAVE_USER: 'Ha ocurrido un error guardando el usuario',
                SAVE_CONTENT: 'Ha ocurrido un error guardando el contenido',
                EDIT_CONTENT: 'Ha ocurrido un error editando el contenido',
                INCORRECT_OPTIONS_AMOUNT: 'Cantidad de opciones incorrecta',
                OPTION: {
                    EXIST: 'Esta opción ya existe'
                },
                LOADING: 'Ha ocurrido un error cargando los datos'
            },
            DROP_YOUR_FILE_HERE: 'Suelte la imagen aquí',
            SELECT_STATUS: 'Seleccione el estado',
            SELECT_SUBSCRIPTION_STATUS: 'Seleccione el tipo de suscripción',
            DELETING: 'Eliminando datos...',
            DELETE: {
                CONTENT_HEADER_CONFIRM: '¿Deseas eliminar el contenido?',
                CONFIRM: 'Si, Eliminar',
                CANCEL: 'Cancelar'
            },
            CONFIRM: {
                DELETE: '¿Seguro desea eliminar estos datos?'
            }
        },
        HEADER: {
            DELETE_TEST: 'Eliminar cuestionario',
            DELETE_QUESTION: 'Eliminar Pregunta',
            DELETE_OPTION: 'Eliminar Opción',
            DELETE_USER: 'Eliminar Usuario',
            DELETE_NEWS: 'Eliminar Noticia',
            UNSYNC_BRACELET: 'Desasignar pulsera',
            DELETE_GROUP: 'Eliminar Grupo',
            DELETE_CATEGORY: 'Eliminar categoría',
            DELETE_CONTENT: 'Eliminar contenido',
            DELETE_PROGRAM: 'Eliminar programa',
            DELETE_BRACELET: 'Eliminar brazalete',
            UPLOAD_FILES: 'Cargar Acrchivo'
        },
        AUTH: {
            GENERAL: {
                OR: 'O',
                SUBMIT_BUTTON: 'Entrar',
                NO_ACCOUNT: 'No tienes una cuenta?',
                SIGNUP_BUTTON: 'Regístrate',
                FORGOT_BUTTON: 'Has olvidado tu contraseña',
                BACK_BUTTON: 'Espalda',
                PRIVACY: 'Intimidad',
                LEGAL: 'Legal',
                CONTACT: 'Contacto',
                TITLE: 'Bienvenido a Mi Tres Torres',
                PRESENTATION_1: 'Bienvenido',
                PRESENTATION_2: 'Estamos orgullosos de que estes aquí'
            },
            LOGIN: {
                TITLE: 'Crear una cuenta',
                BUTTON: 'Registrarse',
            },
            FORGOT: {
                TITLE: 'Contraseña olvidada?',
                DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
                SUCCESS: 'Su contraseña ha sido reseteada correctamente.'
            },
            REGISTER: {
                TITLE: 'Registrate',
                DESC: 'Introduce algunos datos para crear tu cuenta',
                SUCCESS: 'Tu cuenta ha sido creada correctamente.'
            },
            INPUT: {
                EMAIL: 'Correo',
                FULLNAME: 'Nombre completo',
                PASSWORD: 'Contraseña',
                CONFIRM_PASSWORD: 'Confirmar contraseña',
                USERNAME: 'Usuario'
            },
            ERROR: {
                LOGIN: 'Los datos de acceso no son correctos.',
            }
        },
        PROFILE: {
            SIGN_OUT: 'Salir',
            USER_PROFILE: 'Perfil de Usuario'
        },
        FOOTER: {
            COPYRIGHT: 'Derechos de Autor'
        },
        BRACELET: {
            HEADER: {
                ASSIGN: 'Asignar pulsera'
            },
            LABEL: {
                NAME: 'Nombre',
                CODE: 'Código',
                CODE_HELPER: 'Introduzca el código de la pulsera',
                PATIENT: 'Paciente',
                LOCATION: 'Ubicación',
                ACTIVE: 'Activar',
                INACTIVE: 'Desactivar',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                BATTERY_LEVEL: 'Nivel de batería',
                NOT_SYNC: 'No sincronizado',
                NOT_PATIENT: 'Todos los pacientes tienen asignados una pulsera',
                NOT_FILTERED_PATIENT: 'No hemos encontrado un paciente que cumpla con el criterio de búsqueda',
                NOT_BRACELET: 'Todos los pulseras estan asignadas'
            },
            MESSAGE: {
                LOADING: {
                    UPDATING_STATUS: 'Cambiando el estado...',
                    UPDATING_BRACELET: 'Actualizando los datos',
                    CREATING_BRACELET: 'Creando la pulsera',
                    LOADING_REQUIRED_DATA: 'Cargando datos...'
                },
                CONFIRM: {
                    UNSYNC: '¿ Seguro desea desasignar la pulsera ?'
                },
                ERROR: {
                    CLOSED_TRACING: 'No hemos encontrado un seguimiento relacionado con estos datos',
                    ASSIGN_BRACELET: 'Ha ocurrido un error asignando la pulsera',
                    CLOSED_TRACING_ERROR: 'Ha ocurrido un error cerrando el seguimiento',
                },
                SUCCESS: {
                    UPDATE_STATUS: 'EL estado ha sido actualizado correctamente',
                    SAVE_BRACELET: 'La operación ha sido realizada con exito',
                    CLOSED_TRACING: 'El seguimiento ha sido cerrado correctamente',
                    ASSIGN_BRACELET: 'Pulsera asignada',
                    START_TRACING: 'Se ha creado un nuevo seguimiento'
                }
            },
            BTN: {
                CREATE_NEW: 'Nueva',
                CREATE: 'Crear',
                EDIT: 'Editar',
                SAVE: 'Guardar',
                CANCEL: 'Cancelar',
                ASSIGN: 'Asignar',
                CLEAR: 'Desasignar',
                INFO: 'Información'
            }
        },
        ADMISSIONDOCUMENT: {
            LABEL: {
                NAME: 'Nombre',
                STATUS: 'Estado',
                DOCUMENT: 'Documento',
                OWNER: 'Paciente',
                CHANGESTATUS:   'Cambiar estado',
                ADMISSIONDATE: 'Fecha de ingreso',
                CHECKEDDATE: 'Fecha de aprobación',
                CODE_HELPER: 'Introduzca el código de la pulsera',
                APPROVE:    'Aprobar',
                REJECT:    'Rechazar',
                DOWNLOAD:    'Descargar',
                CATEGORY: 'Categoría',
            },
            MESSAGE: {
                LOADING: {
                    UPDATING_STATUS: 'Cambiando el estado...',
                    LOADING_REQUIRED_DATA: 'Cargando datos...',
                },
                REJECT_MESSAGE_LABEL: 'El mensaje escrito será mostrado al paciente',
                REJECT_MESSAGE: 'Escribe aquí el motivo del rechazo',
                CONFIRM: {
                    STATUS_REJECT: '¿ Seguro desea rechazar este documento ?',
                    STATUS_APROVE: '¿ Seguro desea aprobar este documento ?'
                },
                ERROR: {
                    UPDATE_STATUS: 'Ha ocurrido un error actualizando los datos',
                    FILE_DOWNLOAD:  'Ha ocurrido un error descargando el documento'
                },
                SUCCESS: {
                    UPDATE_STATUS: 'EL estado ha sido actualizado correctamente',
                    SAVE_BRACELET: 'La operación ha sido realizada con exito',
                    CLOSED_TRACING: 'El seguimiento ha sido cerrado correctamente',
                    ASSIGN_BRACELET: 'Pulsera asignada',
                    START_TRACING: 'Se ha creado un nuevo seguimiento'
                }
            },
            STATUS: {
                approved: 'Aprobado',
                pending: 'Pendiente',
                validating: 'Pendiente de validar',
                reject: 'Rechazado',
            },
            BTN: {
                CREATE_NEW: 'Nueva',
                CREATE: 'Crear',
                EDIT: 'Editar',
                SAVE: 'Guardar',
                CANCEL: 'Cancelar',
                ASSIGN: 'Asignar',
                CLEAR: 'Desasignar',
                INFO: 'Información'
            }
        },
        APPOINTMENT_DOCUMENT: {
            CATEGORY: {
                electro: 'Electro',
                radiography: 'Radiografía',
                analytics: 'Analítica',
                otherTest: 'Otras pruebas',
            }
        },
        STAGE: {
            BTN: {
                CREATE_NEW: 'Nueva Sala',
                CREATE: 'Crear',
                EDIT: 'Editar',
                SAVE: 'Guardar',
                CANCEL: 'Cancelar',
                ADD_SPACE: 'Agregar Espacios',
                DELETE: 'Eliminar'
            },
            LABEL: {
                NAME: 'Nombre de la etapa',
                ICON: 'Ícono de etapa',
                CHOOSE: 'Elegir imagen',
                QUICK_ACTION: 'Acciones',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                ADD_STAGE: 'Añadir nueva etapa',
                ADD_SPACE: 'Añadir nuevo espacio',
                SPACE_HEADER_MANAGEMENT: 'Gestión de Espacio',
                DELETE_STAGE: 'Eliminar Etapa',
                DELETING: 'Eliminando',
                CONFIRM_DELETE: '¿Estas seguro que quieres borrar la etapa y todos los espacios asociados?'
            },
            MESSAGE: {
                LOADING: {
                    UPDATING_STAGE: 'Actualizando las salas',
                    CREATING_STAGE: 'Creando la Sala',
                    LOADING_REQUIRED_DATA: 'Cargando datos...',
                    UPDATING_SPACE: 'Actualizando los espacios'
                },
                SUCCESS: {
                    UPDATE_STATUS: 'El estado ha sido actualizado correctamente',
                    SAVE_BRACELET: 'La operación ha sido realizada con exito'
                },
                ERROR: {
                    UPDATE_STAGE: 'Hemos tenido un error actualizando la sala',
                    UPDATE_SPACE: 'Hemos tenido un error actualizando los espacios'
                }
            },
        },
        SPACE: {
            BTN: {
                CANCEL: 'Cancelar',
                DELETE: 'Eliminar'
            },
            LABEL: {
                DELETE_STAGE: 'Eliminar Espacio',
                DELETING: 'Eliminando',
                CONFIRM_DELETE: '¿Estas seguro que quieres borrar este espacio?',
                START_LATITUDE: 'Latitud 1',
                END_LATITUDE: 'Latitud 2',
                START_LONGITUDE: 'Longitud 1',
                END_LONGITUDE: 'Longitud 2'
            },
        },
        TRACING: {
            MESSAGE: {
                LOADING_TRACING_DATA: 'Cargando seguimiento del paciente',
                ERROR: {
                    CODE_NOT_FOUND: 'No hemos encontrado este código'
                }
            },
            FAMILY_CODE: {
                FAMILY_CODE_CTA: 'Código familiar',
                INTRODUCE_CODE: 'Introduce el código de tu familiar',
                FIND_CODE: 'Encuéntralo en el mensaje que te ha enviado tu familiar.'
            },
            LABEL: {
                CODE: 'Código',
                VALIDATE: 'Validar',
                PATIENT: 'Paciente:',
                TRACING: 'Seguimiento de la operación',
                INFO: 'Sigue el proceso de la intervención y mira en cada momento dónde se encuentra tu familiar:',
                PATIENT_NOT_HERE: 'El paciente aún no ha llegado',
                INFO_SOON: 'Esperamos informarte pronto de dónde se encuentra tu familiar',
                PREVIOUSLY: 'Anterior',
                LAST_UPDATE: 'Ultima actualización:',
                START_TIME: 'a las',
                START_DATE: 'Entrada',
                END_TIME: ' a las',
                CONECTION_TIME: ' A las',
                END_DATE: 'Salida',
                ANY_QUESTION: '¿Tienes alguna consulta?',
                CALL_US: 'Llámanos',
                BOUTIQUE: 'Boutique',
                FAMILY_SURPRISE: 'Sorprende a tu familiar',
                DETAIL: 'Saber más sobre Mi Tres Torres',
                HOW_TO_GET: 'Como llegar',
                ALEX_FOUNDATION: 'Fundación Alex',
                SPECIALTYS: 'Especialidades',
                COME_WAITING: 'Ven a ver a tu familiar,te esperamos',
                COLABORATE_US: 'Colabora con nosotros',
                MORE_INFO: 'Más información sobre los servicios',
                ARE_HERE:   'Estamos aquí',
                TO_TAKE_CARE:   'para cuidarte',
                FOLLOW_INTERV:   'Sigue la intervención de tu familiar para',
                WATCH_PROCESS:   'ver en todo momento cómo va el proceso',
                APP_DOWNLOAD:   'Descárgate la aplicación',
                INTRODUCE_CODE:   'Introduce el código',
                ERROR_IN_BRACELET:   'El sistema de seguimiento de paciente ha perdido la conexión. Es posible que pronto la recuperemos.',
                CONECTION_LOST:   'Pérdida de conexión',
                SPACE: {
                    TITLE:  'Espacio',
                    ENTER_BLOCK: 'Entrada a Bloque Quirúrgico',
                    ENTER_PRE_REA: 'Pre Rehabilitación',
                    ENTER_QUIR: 'Quirófano',
                    ENTER_REA: 'Rehabilitación',
                    ENTER_LEAVE_BLOCK: 'Salida de Bloque Quirúrgico'
                }
            }
        },
        DEFAULT_FALLBACK_MESSAGE: 'La operación se ha realizado con éxito'
    }

};
