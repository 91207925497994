import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../modules/auth';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptorInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const auth = this.auth.getAuthFromLocalStorage();


        if (auth !== null && auth.token) {
            request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + auth.token)});
        }

        if (request.url.indexOf('/graphql') > -1 || request.url.indexOf('/custom-operations') > -1) {
            request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
            request = request.clone({headers: request.headers.set('Accept', 'application/json')});
        } else if (request.url.indexOf('/multimedia') < 0) {
            request = request.clone({headers: request.headers.set('Content-Type', 'application/ld+json')});
            request = request.clone({headers: request.headers.set('Accept', 'application/ld+json')});
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse){
                    // TODO Remove when finish develop
                    console.log(event);
                }
                return event;
            }),
            catchError((error) => {
                console.log('err', error);
                return throwError(error);
            })
        );
    }
}
