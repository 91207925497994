import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class CustomLoaderService {
  // tslint:disable-next-line:variable-name
  private _config: BehaviorSubject<NgxUiLoaderConfig>;
  config: Observable<NgxUiLoaderConfig>;

  constructor(public ngxUiLoaderService: NgxUiLoaderService) {
    const ngxUiLoaderConfig: NgxUiLoaderConfig = {
      bgsColor: 'red',
      bgsOpacity: 0.5,
      bgsPosition: 'bottom-right',
      bgsSize: 60,
      bgsType: 'folding-cube',
      blur: 3,
      delay: 0,
      fastFadeOut: true,
      fgsColor: '#f8897f',
      fgsPosition: 'center-center',
      fgsSize: 40,
      fgsType: 'folding-cube',
      gap: 24,
      logoPosition: 'center-center',
      logoSize: 120,
      logoUrl: '',
      masterLoaderId: 'master',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(40, 40, 40, 0.8)',
      pbColor: 'red',
      pbDirection: 'ltr',
      pbThickness: 3,
      hasProgressBar: false,
      textColor: '#ffffffff',
      textPosition: 'center-center',
      maxTime: -1,
      minTime: 300
    };

    this._config = new BehaviorSubject<NgxUiLoaderConfig>(ngxUiLoaderConfig);
    this.config = this._config.asObservable();
  }

  start(config: any) {
    this._config.next(Object.assign(this._config.value, config));
    this.ngxUiLoaderService.start();
  }

  stop() {
    this.ngxUiLoaderService.stop();
  }
}
