import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {UserModel} from '../_models/user.model';
import {AuthModel} from '../_models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import jwtDecode from 'jwt-decode';
import {IVerify} from '../_models/iverify';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private ROLE_CLINICAL: Array<string> = ['dashboard', 'patients', 'admission', 'appointment'];
    private ROLE_ADMIN: Array<string> = [...this.ROLE_CLINICAL, 'rtls', 'user-profile', 'user-management', 'admission', 'news-managment', 'notification-form'];
    private ROLES: any = {
        ROLE_CLINICAL: this.ROLE_CLINICAL,
        ROLE_ADMIN: this.ROLE_ADMIN
    };

    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    currentUser$: Observable<UserModel>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserModel>;
    isLoadingSubject: BehaviorSubject<boolean>;


    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private authHttpService: AuthHTTPService,
        private router: Router
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);
    }

    // public methods
    login(email: string, password: string): Observable<UserModel> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.login(email, password).pipe(
            map((auth: AuthModel) => {
                return this.setAuthFromLocalStorage(auth);
            }),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    hasAccess(route = null): Promise<boolean> {
        const jwt = localStorage.getItem(this.authLocalStorageToken);

        if (jwt) {
            return new Promise((resolve, _) => {
                this.authHttpService.verify().subscribe((res: IVerify) => {
                        // (res.status) ? resolve(true) : resolve(false);
                        if (res.status) {
                            const userRole = this.currentUserValue.roles;
                            if (userRole.length > 0 && this.ROLES.hasOwnProperty(userRole[0])) {
                                const permits = this.ROLES[userRole[0]];
                                if (permits.includes(route)) {
                                    resolve(true);
                                } else {
                                    this.router.navigateByUrl('/patients/assigned')
                                        .then()
                                        .catch(err => console.log(err));
                                }
                            }
                            resolve(false);
                        }
                        resolve(false);
                        },
                        (errorVerify) => {
                            if (errorVerify.error.code === 401 && errorVerify.error.message === 'Expired JWT Token') {
                                this.refreshToken().subscribe(() => {
                                    resolve(true);
                                }, error => {
                                    console.log('Error refresh token', error);
                                    this.logout();
                                });
                            } else {
                                this.logout();
                                resolve(false);
                            }
                        });
            });
        } else {
            this.logout();
            return Promise.resolve(false);
        }
    }

    refreshToken() {
        return this.authHttpService.refreshToken(this.getAuthFromLocalStorage().refresh_token).pipe(
            tap((auth: AuthModel) => {
                this.setAuthFromLocalStorage(auth);
            })
        );
    }

    logout() {
        localStorage.removeItem(this.authLocalStorageToken);
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        }).then(r => {
            console.log(r);
        });
    }

    getUserByToken(): Observable<UserModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.token) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.tokenDecode(auth.token).pipe(
            map((user: UserModel) => {
                if (user) {
                    this.currentUserSubject = new BehaviorSubject<UserModel>(user);
                } else {
                    this.logout();
                }
                return user;
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    // private methods
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        if (auth && auth.token) {
            if (localStorage.getItem(this.authLocalStorageToken) !== null) {
                localStorage.removeItem(this.authLocalStorageToken);
            }
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    getAuthFromLocalStorage(): AuthModel {
        try {
            return JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    tokenDecode(token: string): Observable<UserModel> {
        return of(jwtDecode(token));
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    isAuthorized(roles: string[] ){
        return this.currentUserValue.roles.some(role => roles.indexOf(role) >= 0);
    }
}
