// Spain
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    TOPBAR: {
      HI: 'Hola'
    },
    MENU: {
      DASHBOARD: 'Tablero',
      PERFORMEDTESTS: 'Cuestionarios Realizados',
      SUBSCRIPTIONS: 'Suscripciones',
      TEST: 'Cuestionarios',
      CATEGORY: 'Categoría',
      CONTENT: 'Contenido',
      MULTIMEDIA: 'Multimedia',
      PROGRAM: 'Programa',
      PROGRAMS_CONTENTS: 'Programas y Contenidos',
      ADMINISTRATION: 'Administración',
      USER: 'Usuario',
      GROUP: 'Grupo',
      QUESTIONS: 'Preguntas'
    },
    PAGINATOR: {
      LOADING: 'Cargando',
      SHOWING_ROWS: 'Mostrando filas',
      TO: 'a',
      OF: 'de'
    },
    FORM: {
      BUTTON: {
        CANCEL: 'Cancelar',
        CREATE_NEW: 'Crear nuevo',
        EDIT: 'Editar',
        DELETE: 'Eliminar',
        VIEW: 'Ver ficha',
        CREATE: 'Crear',
        BACK: 'Volver',
        RESET: 'Restaurar',
        SAVE: 'Guardar',
        ADD: 'Adicionar',
        CHANGE: 'Cambiar',
        UPLOAD: 'Cargar',
        CHANGE_PASSWORD: 'Cambiar contraseña',
        SELECT: {
          STATUS: 'Estado',
          LOCALE: 'Idioma',
          LOCALE_EN: 'Inglás',
          LOCALE_ES: 'Español',
          LOCALE_CA: 'Català',
          ANSWER_TYPE: 'Tipo de respuesta',
        },
        ROLES: {
          ROLES: 'Roles',
          ESPECIALIST: 'Especialista',
          ADMIN: 'Administrador',
          USER: 'Usuario',
          PATIENT: 'Paciente'
        }
      },
      VALIDATION: {
        INVALID: 'Campo inválido',
        REQUIRED: 'Campo requerido',
        MIN_LENGTH: 'La longitud mínima de {{name}} es {{min}}',
        AGREEMENT_REQUIRED: 'Aceptar los Términos y Condiciones es obligatorio',
        NOT_FOUND: 'No se ha encontrado {{name}}',
        INVALID_LOGIN: 'El login es incorrecto',
        REQUIRED_FIELD: 'Este campo es requerido',
        MIN_LENGTH_FIELD: 'Mínima longitud del campo:',
        MAX_LENGTH_FIELD: 'Máxima longitud del campo:',
        INVALID_FIELD: 'El campo no es válido',
        VALIDATE_PASSWORD_REQUIRED: 'Validación de la contraseña requerida',
        VALIDATE_PASSWORD_MIN_SYMBOLS: 'Validación mínima de caracteres en la contraseña',
        VALIDATE_PASSWORD_MAX_SYMBOLS: 'Validación máxima de caracteres en la contraseña',
        VALIDATE_EMAIL_REQUIRE: 'Validación de correo requerida',
        VALIDATE_EMAIL_INVALID: 'Validación de correo invalida',
        VALIDATE_EMAIL_MIN_SYMBOLS: 'Validación mínima de caracteres en el correo',
        VALIDATE_EMAIL_MAX_SYMBOLS: 'Validación máxima de caracteres en el correo',
        NAME_REQUIRED: 'Este campo es requerido',
        MAX_LENGTH: 'Cantidad de caracteres excedida',
        DESCRIPTION_REQUIRED: 'Este campo es requerido',
        SUCCESS: 'El valor de este campo es correcto',
      },
      PLACEHOLDER: {
        SEARCH: 'Buscar'
      },
      HELPER: {
        SEARCH_IN_ALL_FIELDS: 'Buscar en todos los campos'
      }
    },
    LABEL: {
      NAME: 'Nombre',
      DATE: 'Fecha',
      LOCALE: 'Idioma',
      ROLE: 'Rol',
      PASSWORD: 'Contraseña',
      SINGLE: 'Única',
      MULTIPLE: 'Multiple',
      OPTIONS: 'Opciones',
      OPTION: 'Opción',
      OPTION_ORDER: 'Orden de Opción',
      ANSWER_TYPE: 'Tipo de Respuesta',
      TOTAL_OPTIONS: 'Total de Opciones',
      TEXT: 'Texto',
      PERIODICITY: 'Periodicidad',
      QUESTIONS: 'Preguntas',
      QUESTION: 'Preguntas',
      DESCRIPTION: 'Descripción',
      TOTAL_QUESTIONS: 'Total de Preguntas',
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
      PREMIUM: 'Premium',
      FREE: 'Gratis',
      STATUS: 'Estado',
      USERNAME: 'Nombre de Usuario',
      PERFORMEDTESTS: 'Cuestionarios Realizados',
      SUBSCRIPTION_TIME: 'Subscrito hasta',
      PLAN: {
        SUBSCRIPTIONS: 'Suscripciones por planes'
      },
      ACTIONS: 'Acciones',
      ALL: 'Todos',
      INFORMATION: 'Información',
      EMAIL: 'Correo electrónico',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de actualización',
      LAST_ACTIVITY: 'Última actividad',
      USER_INFO: 'Información de usuario',
      WATCH: 'Ver',
      LASTNAME: 'Apellidos',
      USER_ID: 'ID de usuario',
      INTERNAL_NAME: 'Identificador interno',
      CENTER: 'Centro',
      ROLES: 'Rol',
      NEW_PASSWORD: 'Nueva contraseña',
      PASSWORD_CONFIRM: 'Confirme contraseña',
      SELF_GENERATED_PASSWORD: 'Contraseña autogenerada',
      CREATE_NEW: 'Nuevo',
      CATEGORY_ORDER: 'Orden',
      SELECTED_MULTIMEDIA: 'Multimedia seleccionada',
      AUTHOR: 'Autor',
      TOTAL_MULTIMEDIAS: '# de multimedias',
      TOTAL_CONTENTS: '# de contenidos',
      TAGS: 'Etiquetas',
      UPLOAD_FILES: 'Subir multimedia',
      SUBSCRIPTION: 'Subscripcion',
      YES: 'Si',
      NO: 'No',
      QUESTION_ORDER: 'Orden de pregunta'
    },
    MESSAGE: {
      LOADING: {
        LOADING_REQUIRED_DATA: 'Cargando datos...',
        UPDATING_GROUP: 'Actualizando Grupo...',
        CREATING_GROUP: 'Creando Grupo...',
        UPDATING_USER: 'Actualizando Usuario...',
        CREATING_USER: 'Creando Usuario...',
        DELETING_USER: 'Eliminando Usuario...',
        UPLOADING_MULTIMEDIA: 'Subiendo multimedia al servidor...'
      },
      SAVING: {
        SAVING_DATA: 'guardando datos...'
      },
      SUCCESS: {
        TEST_USER: 'Cuestionario guardado con éxito',
        SAVE_GROUP: 'Grupo guardado con éxito',
        SAVE_USER: 'Usuario guardado con éxito',
        INCORRECT_OPTIONS_AMOUNT: 'Cantidad de opciones incorrecta',
        UPDATE_CATEORY: 'Categoría guardada con éxito',
        SAVE_PROGRAM: 'Programa guardado con éxito',
        SAVE_CONTENT: 'Contenido guardado con éxito'
      },
      ERROR: {
        TEST_USER: 'Ha ocurrido un error guardando el cuestionario',
        SAVE_GROUP: 'Ha ocurrido un error guardando el grupo',
        SAVE_USER: 'Ha ocurrido un error guardando el usuario',
        EDIT_CONTENT: 'Ha ocurrido un error editando el contenido',
        INCORRECT_OPTIONS_AMOUNT: 'Cantidad de opciones incorrecta',
        OPTION: {
          EXIST: 'Esta opción ya existe'
        }
      },
      DROP_YOUR_FILES_HERE: 'Suelte sus archivos aquí',
      SELECT_STATUS: 'Seleccione el estado',
      SELECT_SUBSCRIPTION_STATUS: 'Seleccione el tipo de suscripción',
      DELETING: 'Eliminando datos...',
      DELETE: {
        CONTENT_HEADER_CONFIRM: '¿Deseas eliminar el contenido?',
        CONFIRM: 'Si, Eliminar',
        CANCEL: 'Cancelar'
      },
      CONFIRM: {
        DELETE: '¿Seguro desea eliminar estos datos?'
      }
    },
    HEADER: {
      DELETE_TEST: 'Eliminar cuestionario',
      DELETE_QUESTION: 'Eliminar Pregunta',
      DELETE_OPTION: 'Eliminar Opción',
      DELETE_USER: 'Eliminar Usuario',
      DELETE_GROUP: 'Eliminar Grupo',
      DELETE_CATEGORY: 'Eliminar categoría',
      DELETE_CONTENT: 'Eliminar contenido',
      DELETE_PROGRAM: 'Eliminar programa',
      UPLOAD_FILES: 'Cargar Acrchivo'
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Espalda',
        PRIVACY: 'Intimidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
        TITLE: 'Authenticación',
        PRESENTATION_1: 'Bienvenido',
        PRESENTATION_2: 'Estamos orgullosos de que estes aquí'
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Registrarse',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada?',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
        SUCCESS: 'Su contraseña ha sido reseteada correctamente.'
      },
      REGISTER: {
        TITLE: 'Registrate',
        DESC: 'Introduce algunos datos para crear tu cuenta',
        SUCCESS: 'Tu cuenta ha sido creada correctamente.'
      },
      INPUT: {
        EMAIL: 'Correo',
        FULLNAME: 'Nombre completo',
        PASSWORD: 'Contraseña',
        CONFIRM_PASSWORD: 'Confirmar contraseña',
        USERNAME: 'Usuario'
      }
    },
    PROFILE: {
      SIGN_OUT: 'Salir',
      USER_PROFILE: 'Perfil de Usuario'
    },
    FOOTER: {
      COPYRIGHT: 'Derechos de Autor'
    },
    DEFAULT_FALLBACK_MESSAGE: 'La operación se ha realizado con éxito'
  }

};
