export class AuthModel {
  token: string;
  refresh_token: string;
  locale: string;

  setAuth(auth: any) {
    this.token = auth.token;
    this.refresh_token = auth.refresh_token;
    this.locale = auth.locale;
  }
}
