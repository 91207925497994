import { AuthModel } from './auth.model';
import DateTimeFormat = Intl.DateTimeFormat;

export class UserModel extends AuthModel {
  id: number;
  username: string;
  password: string;
  name: string;
  email: string;
  pic: string;
  roles: string[];
  center: string;
  creationTime: DateTimeFormat;
  updatedTime: DateTimeFormat;
  locale: string;

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.name = user.name || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.roles = user.roles || [];
    this.locale = 'es';
  }
}
